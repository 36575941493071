import countryList from 'react-select-country-list';

import { DonationDrive } from '../../lib/rails_api/getHomeData';
import { Products } from '../../lib/rails_api/getProducts';
import { RetrievedContact } from '../../lib/rails_api/retrieveContactDetailsForRenewCheckout';
import { RetrievedSavedOrder } from '../../lib/rails_api/retrieveSavedSubscriptionOrderForCheckout';
import { DonationPrice } from '../../types/DonationPrice';
import {
  ageOptions,
  agePreferNotToSayOption,
  SubscribeFormDefaultValues,
  SubscribeFormValues,
} from '../../types/FormValues';
import { donationOptions } from './SubscriptionDetailsFormPane';

export type SavedOrderOptions = {
  selectedProductId?: never;
  savedOrder: RetrievedSavedOrder;
  contactForRenew?: never;
};

export type ContactRenewOptions = {
  selectedProductId: number;
  savedOrder?: never;
  contactForRenew: RetrievedContact;
};

export type ProductIdOptions = {
  selectedProductId: number;
  savedOrder?: never;
  contactForRenew?: never;
};

export type GetSubscribeFormDefaultValuesOptions =
  | SavedOrderOptions
  | ProductIdOptions
  | ContactRenewOptions;

export function getSubscribeFormDefaultValuesImpl(
  products: Products,
  donationPrices: DonationPrice[],
  options: GetSubscribeFormDefaultValuesOptions,
  donationDrive?: DonationDrive
): SubscribeFormValues {
  const savedOrder = options.savedOrder;
  const contactForRenew = options.contactForRenew;

  const selectedProductId = options.savedOrder
    ? options.savedOrder.subscription_product_id
    : (options as ProductIdOptions | ContactRenewOptions).selectedProductId;

  const selectedProduct = products.subscription_products.find(
    (product) => product.id === selectedProductId
  );

  const data = {
    ...SubscribeFormDefaultValues,
    productId: selectedProductId,
    vouchers: new Array(selectedProduct ? selectedProduct.voucher_count : 0).fill({ id: '-1' }),
  };

  const savedContactDetails = savedOrder || contactForRenew;
  if (savedContactDetails) {
    data.firstName = savedContactDetails.first_name;
    data.lastName = savedContactDetails.last_name;
    data.email = savedContactDetails.email;
    data.telephoneNumber = savedContactDetails.phone || null;

    const countryOption = countryList()
      .getData()
      .find((item) => item.value === savedContactDetails.country);
    if (countryOption) {
      data.country = countryOption;
    }

    data.address1 = savedContactDetails.address1;
    data.address2 = savedContactDetails.address2 || null;

    if (savedContactDetails.country === 'AU' && savedContactDetails.auLocality) {
      const locality = savedContactDetails.auLocality;
      data.auPostcodeSuburb = {
        label: `${locality.location}, ${locality.postcode}, ${locality.state}`,
        value: {
          location: locality.location,
          postcode: locality.postcode,
          state: locality.state,
        },
      };
    } else {
      data.suburb = savedContactDetails.suburb;
      data.postcode = savedContactDetails.postcode;
      data.state = savedContactDetails.state;
    }
  }

  if (contactForRenew) {
    data.contactId = contactForRenew.id;
    data.reminderId = contactForRenew.reminderId;
    data.viewToken = contactForRenew.viewToken;
  }

  if (savedOrder) {
    data.productId = savedOrder?.subscription_product_id;

    if (savedOrder.favourite_show === null) {
      data.favouriteShow = { value: '', label: 'All' };
    } else {
      data.favouriteShow = { value: savedOrder.favourite_show, label: savedOrder.favourite_show };
    }

    data.vouchers = savedOrder.radiothon_vouchers.map((voucherId) => ({
      id: voucherId.toString(),
    }));

    data.numberOfCards = savedOrder.number_of_cards ?? 1;

    data.age =
      ageOptions.find((option) => option.value === savedOrder.age) ?? agePreferNotToSayOption;

    data.petName = savedOrder.pet_name;

    if (savedOrder.status !== 'pledged') {
      while (data.vouchers.length < (selectedProduct?.voucher_count || 0)) {
        data.vouchers.push({ id: '-1' });
      }
    }

    if (savedOrder.donation_amount_cents > 0) {
      if (selectedProduct?.is_donation) {
        const donationPrice = donationPrices.find(
          (item) =>
            item.amountCents === savedOrder.donation_amount_cents && item.term == savedOrder.term
        );
        if (donationPrice) {
          data.presetDonationAmount = donationPrice.id;
        } else {
          data.presetDonationAmount = 'other';
          data.customDonationAmount = (savedOrder.donation_amount_cents / 100).toString();
        }
      } else {
        const presetDonationOption = donationOptions.find(
          (item) => parseInt(item.value) * 100 == savedOrder.donation_amount_cents
        );
        if (presetDonationOption) {
          data.presetDonationAmount = presetDonationOption.value;
        } else {
          data.presetDonationAmount = 'other';
          data.customDonationAmount = (savedOrder.donation_amount_cents / 100).toString();
        }
      }
    }

    data.subscriptionOrderId = savedOrder.subscriptionOrderId;
    data.viewToken = savedOrder.viewToken;
    data.orderStatus = savedOrder.status;
    data.term = savedOrder.term;

    data.giftPurchaserEmail = savedOrder.gift_purchaser_email;
    data.giftPurchaserName = savedOrder.gift_purchaser_name;
  } else {
    if (donationDrive && donationDrive.default_donation_amount) {
      data.presetDonationAmount = 'other';
      data.customDonationAmount = (donationDrive.default_donation_amount / 100).toString();
    }
  }
  return data;
}

export function getSubscribeFormDefaultValues(
  savedOrder: RetrievedSavedOrder | undefined,
  contactForRenew: RetrievedContact | undefined,
  originalProductId: number | undefined,
  routerQueryProductId: string,
  products: Products,
  donationPrices: DonationPrice[],
  donationDrive?: DonationDrive
): SubscribeFormValues {
  let options: GetSubscribeFormDefaultValuesOptions;
  if (savedOrder) {
    options = { savedOrder };
  } else if (contactForRenew) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    options = { contactForRenew, selectedProductId: originalProductId! };
  } else {
    if (routerQueryProductId === 'continue') {
      options = { selectedProductId: -1 };
    } else {
      // Note this is just the product id selected via the URL. The user can
      // update the product via the page UI so this may not remain the selected
      // product.
      const originalProductId = parseInt(routerQueryProductId);
      options = { selectedProductId: originalProductId };
    }
  }
  return getSubscribeFormDefaultValuesImpl(products, donationPrices, options, donationDrive);
}
