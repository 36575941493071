import { useUser } from '@auth0/nextjs-auth0';
import { Alert, AlertIcon, Box, Button, Center, Spinner, Text } from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';
import { useEffect } from 'react';

type ReccuringPaymentLoginBoxSectionProps = {
  autoRenew: boolean;
  thanksMessage: string;
  logoutReturnTo: string;
  loginReturnTo: string;
};

export function RecurringPaymentLoginBoxSection(
  props: ReccuringPaymentLoginBoxSectionProps
): JSX.Element {
  const { user, checkSession } = useUser();

  useEffect(() => {
    const interval = setInterval(async () => {
      if (props.autoRenew && user?.email && !user.email_verified) {
        await fetch('/api/profile/refetch');
        checkSession();
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [checkSession, user, props.autoRenew]);

  return (
    <div style={{ display: props.autoRenew ? 'block' : 'none' }}>
      {user?.email && (
        <Box>
          <Alert status="success" alignItems="flex-start">
            <AlertIcon />
            <Box>
              <Text fontSize="lg">{props.thanksMessage}</Text>
              <Text mt="2">
                You&apos;re currently signed in as {user.email} and we&apos;ll associate the
                recurring payments with this account. If you&apos;d like to use another account,
                please sign out and sign in again.
              </Text>
              <Box mt="2" mb="2">
                <Link
                  href={`/api/auth/logout?returnTo=${encodeURIComponent(props.logoutReturnTo)}`}
                >
                  <Button variant="solid" size="sm" colorScheme="green">
                    Sign Out
                  </Button>
                </Link>
              </Box>
            </Box>
          </Alert>

          {!user.email_verified && (
            <Alert status="info" mt="4" alignItems="flex-start">
              <AlertIcon />
              <Box>
                <Text>
                  We&apos;ve sent a verification email to you to confirm your email address. Please
                  check your emal inbox and click the link in the verification email.
                </Text>
                <Text>
                  Once verification is complete, you can return to this page and finalise your
                  checkout.
                </Text>
                <Center>
                  <Spinner
                    my="4"
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  />
                </Center>
              </Box>
            </Alert>
          )}
        </Box>
      )}
      {!user?.email && (
        <>
          <Alert status="info" alignItems="flex-start">
            <AlertIcon />
            <Box>
              That&apos;s great! We just need you to sign in / sign up so that you can can
              self-manage your auto-renew.
            </Box>
          </Alert>

          <Box py="8">
            <Link href={`/api/auth/login?returnTo=${encodeURIComponent(props.loginReturnTo)}`}>
              <Button variant="solid" colorScheme="pink">
                Sign In / Sign Up
              </Button>
            </Link>
          </Box>
        </>
      )}
    </div>
  );
}
