import { Box, useRadio, useRadioGroup, UseRadioProps, Wrap } from '@chakra-ui/react';
import React from 'react';

import frontendTheme from '../../styles/frontendTheme';

type PresetOption = {
  label: string;
  value: string;
};

function RadioCard(
  props: (UseRadioProps | undefined) & { children: React.ReactNode }
): JSX.Element {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        display="inline-block"
        cursor="pointer"
        borderWidth="2px"
        borderRadius="md"
        bg={frontendTheme.input.backgroundColor}
        borderColor={frontendTheme.input.backgroundColor}
        color={frontendTheme.primaryButtonBackground}
        _checked={{
          borderColor: frontendTheme.primaryButtonBackground,
        }}
        _focus={{
          borderColor: frontendTheme.primaryButtonBackground,
          backgroundColor: frontendTheme.input.focusedBackgroundColor,
        }}
        px={3}
        py={1}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export function RadioButtonGroup(props: {
  options: PresetOption[];
  onChange: (nextValue: string) => void;
  value: string;
  name: string;
}): JSX.Element {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: props.name,
    onChange: props.onChange,
    value: props.value,
  });

  const group = getRootProps();

  return (
    <Wrap spacing="20px" {...group}>
      {props.options.map((option) => {
        const radio = getRadioProps({ value: option.value });
        return (
          <RadioCard key={option.value} {...radio}>
            {option.label}
          </RadioCard>
        );
      })}
    </Wrap>
  );
}
