import {
  FormControl,
  FormLabel,
  Alert,
  AlertIcon,
  Box,
  Stack,
  InputGroup,
  FormErrorMessage,
  InputLeftAddon,
  InputRightAddon,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import React from 'react';
import { Control, Controller, FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form';

import { DonationPrice } from '../../types/DonationPrice';
import { SubscribeFormValues } from '../../types/FormValues';
import { FormPaneWrapper } from '../form/FormPaneWrapper';
import { RadioButtonGroup } from '../form/RadioButtonGroup';
import { RecurringPaymentLoginBoxSection } from './RecurringPaymentLoginBoxSection';

type Props = {
  control: Control<SubscribeFormValues>;
  register: UseFormRegister<SubscribeFormValues>;
  watch: UseFormWatch<SubscribeFormValues>;
  errors: FieldErrors<SubscribeFormValues>;
  shows: string[];
  donationPrices: DonationPrice[];
};
export function DonationDetailsFormPane(props: Props): JSX.Element {
  const presetDonationAmount = props.watch('presetDonationAmount');
  const term = props.watch('term');

  const presetDonationOptions = props.donationPrices
    .filter((price) => price.term === term)
    .map((price) => ({ value: price.id, label: price.textAmount }));

  const donationOptions = [...presetDonationOptions, { value: 'other', label: 'other' }];

  return (
    <FormPaneWrapper>
      <Stack direction="column" spacing="4">
        <FormControl>
          <FormLabel>
            What type of donation would you like to make?
            <Alert status="info" variant="outline">
              <AlertIcon />
              You can cancel recurring donations online at any time
            </Alert>
            <Controller
              name="term"
              control={props.control}
              render={({ field }) => (
                <Box mt="2">
                  <RadioButtonGroup
                    options={[
                      { value: 'one_time', label: 'one-off' },
                      { value: 'year', label: 'yearly' },
                      { value: 'month', label: 'monthly' },
                    ]}
                    onChange={field.onChange}
                    value={field.value}
                    name={field.name}
                  />
                </Box>
              )}
            />
          </FormLabel>
        </FormControl>

        <RecurringPaymentLoginBoxSection
          autoRenew={term !== 'one_time'}
          thanksMessage="Thanks for choosing to make a recurring donation!"
          loginReturnTo="/subscribe/continue"
          logoutReturnTo="/subscribe/continue"
        />

        <FormControl isInvalid={props.errors.presetDonationAmount !== undefined}>
          <FormLabel>
            What would you like to donate?
            <Controller
              name="presetDonationAmount"
              control={props.control}
              rules={{ validate: (theValue) => theValue !== '0' }}
              render={({ field }) => (
                <Box mt="2">
                  <RadioButtonGroup
                    options={donationOptions}
                    onChange={field.onChange}
                    value={field.value}
                    name={field.name}
                  />
                </Box>
              )}
            />
          </FormLabel>
          <FormErrorMessage>
            {props.errors.presetDonationAmount && <span>This field is required</span>}
          </FormErrorMessage>
        </FormControl>

        {presetDonationAmount === 'other' && (
          <FormControl isInvalid={props.errors.customDonationAmount !== undefined}>
            <FormLabel>
              Donation Amount
              <InputGroup variant="filled">
                <InputLeftAddon>$</InputLeftAddon>
                <NumberInput defaultValue={0} min={0} precision={0} variant="filled">
                  <NumberInputField
                    {...props.register('customDonationAmount', {
                      required: true,
                      min: 2,
                    })}
                  />
                </NumberInput>
                <InputRightAddon>.00</InputRightAddon>
              </InputGroup>
            </FormLabel>
            <FormErrorMessage>
              {props.errors.customDonationAmount && (
                <span>Please enter an amount of at least two dollars</span>
              )}
            </FormErrorMessage>
          </FormControl>
        )}
      </Stack>
    </FormPaneWrapper>
  );
}
