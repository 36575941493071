import { Box, Button } from '@chakra-ui/react';
import { useStripe } from '@stripe/react-stripe-js';
import React, { useContext } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';

import { saveSubcriptionOrder } from '../../lib/client_api/frontend_subscription';
import { Products, SubscriptionProduct } from '../../lib/rails_api/getProducts';
import { RetrievedSavedOrder } from '../../lib/rails_api/retrieveSavedSubscriptionOrderForCheckout';
import { DonationPrice } from '../../types/DonationPrice';
import { SubscribeFormValues } from '../../types/FormValues';
import { RadiothonVoucher } from '../../types/RadiothonVoucher';
import { ErrorPopupContext } from '../ErrorPopup';
import { DonationDetailsFormPane } from '../donate/DonationDetailsFormPane';
import GiftDonorFormPane from '../form/GiftDonorFormPane';
import CustomerDetailsFormPane from './CustomerDetailsFormPane';
import FavouriteShowFormPane from './FavouriteShowFormPane';
import { SubscriptionDetailsFormPane } from './SubscriptionDetailsFormPane';
import { VoucherFormPane } from './VoucherFormPane';

export function SubscribeForm(props: {
  product: SubscriptionProduct;
  products: Products;
  donationPrices: DonationPrice[];
  savedOrder?: RetrievedSavedOrder;
  shows: string[];
  radiothonVouchers: RadiothonVoucher[];
  useFormReturnResult: UseFormReturn<SubscribeFormValues>;
}): JSX.Element {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = props.useFormReturnResult;

  const term = watch('term');
  const stripe = useStripe();
  const errorPopupContext = useContext(ErrorPopupContext);

  const onSubmit = async (data: SubscribeFormValues): Promise<void> => {
    if (!stripe) {
      return;
    }
    try {
      const saveResult = await saveSubcriptionOrder(data);
      stripe.redirectToCheckout({ sessionId: saveResult.sessionId });
    } catch (err) {
      errorPopupContext.setErrorMessage((err as { message?: string }).message || 'Unknown error');
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...props.useFormReturnResult}>
        {props.product.is_donation && (
          <DonationDetailsFormPane
            donationPrices={props.donationPrices}
            control={control}
            register={register}
            watch={watch}
            errors={errors}
            shows={props.shows}
          />
        )}
        {!props.product.is_donation && (
          <SubscriptionDetailsFormPane
            control={control}
            register={register}
            watch={watch}
            errors={errors}
            product={props.product}
            shows={props.shows}
            radiothonVouchers={props.radiothonVouchers}
          />
        )}

        {term === 'one_time' && <GiftDonorFormPane />}

        <FavouriteShowFormPane shows={props.shows} />
        <CustomerDetailsFormPane
          shows={props.shows}
          isCompanyOrOrg={props.product.is_company_or_org}
          hasPetName={props.product.has_pet_name}
          isRecurring={term !== 'one_time'}
          cardCount={props.product.card_count}
        />
        <VoucherFormPane
          control={control}
          product={props.product}
          radiothonVouchers={props.radiothonVouchers}
          watch={watch}
          setValue={setValue}
        />
        <Box textAlign="center" mb="20">
          <Button colorScheme="pink" size="lg" type="submit">
            Checkout
          </Button>
        </Box>
      </FormProvider>
    </Box>
  );
}
