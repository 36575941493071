export function ensureCorrectVoucherCount(
  vouchers: { id: string }[],
  count: number
): { id: string }[] {
  let newVouchers: {
    id: string;
  }[] = [];
  if (count < vouchers.length) {
    newVouchers = vouchers.slice(0, count - 1);
  }
  while (count > newVouchers.length) {
    newVouchers.push({ id: '-1' });
  }
  return newVouchers;
}
