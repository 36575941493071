import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  useDisclosure,
  Text,
  Stack,
} from '@chakra-ui/react';
import { DevTool } from '@hookform/devtools';
import { Elements } from '@stripe/react-stripe-js';
import { useRouter } from 'next/router';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { Control, useForm, UseFormWatch } from 'react-hook-form';

import DonationDrive from '../../components/DonationDrive';
import { BottomSection, Sticky, useStickyMonitor } from '../../components/FrontendFormPage';
import { FrontendLayout } from '../../components/FrontendLayout';
import ProductImage from '../../components/home/ProductImage';
import { SubscribeForm } from '../../components/subscribe/SubscribeForm';
import SubscriptionChooser from '../../components/subscribe/SubscriptionChooser';
import { ensureCorrectVoucherCount } from '../../components/subscribe/ensureCorrectVoucherCount';
import { getSubscribeFormDefaultValues } from '../../components/subscribe/getSubscribeFormDefaultValues';
import { getHomeData, HomePageData } from '../../lib/rails_api/getHomeData';
import { getProducts, Products, SubscriptionProduct } from '../../lib/rails_api/getProducts';
import { RetrievedContact } from '../../lib/rails_api/retrieveContactDetailsForRenewCheckout';
import {
  OutstandingOderStatus,
  RetrievedSavedOrder,
} from '../../lib/rails_api/retrieveSavedSubscriptionOrderForCheckout';
import { getDonationPrices } from '../../lib/server/donation_prices';
import { createStripeServerSide, getStripeConfig } from '../../lib/server/stripe';
import useFormPersist from '../../lib/useFormPersist';
import frontendTheme from '../../styles/frontendTheme';
import { DonationPrice } from '../../types/DonationPrice';
import { SubscribeFormValues } from '../../types/FormValues';
import { RadiothonVoucher } from '../../types/RadiothonVoucher';
import getStripe from '../../utils/get-stripejs';

function getHeadingText(orderStatus: OutstandingOderStatus | null, isDonation: boolean): string {
  if (orderStatus === 'pledged') {
    return 'Pay Your Pledge';
  }
  if (isDonation) {
    return 'Donate to RTRFM';
  } else {
    return 'Subscribe to RTRFM';
  }
}

export function getDonation(
  presetDonationAmount: string,
  customDonationAmount: string,
  product: SubscriptionProduct,
  donationPrices: DonationPrice[]
): number {
  let donationAmountCents = 0;

  if (product.is_donation && presetDonationAmount !== 'other') {
    const donationPriceItem = donationPrices.find((price) => price.id === presetDonationAmount);
    if (!donationPriceItem) {
      donationAmountCents = 0;
    } else {
      donationAmountCents = donationPriceItem.amountCents;
    }
  } else {
    if (presetDonationAmount !== 'other') {
      donationAmountCents = parseInt(presetDonationAmount) * 100; // UI input is in dollars
    } else {
      donationAmountCents =
        customDonationAmount.length == 0 ? 0 : parseInt(customDonationAmount) * 100; // UI input is in dollars
    }
  }
  return donationAmountCents;
}

function TitleAndPriceIndicator(props: {
  product: SubscriptionProduct;
  donationPrices: DonationPrice[];
  customDonationAmountStripeId: string;
  watch: UseFormWatch<SubscribeFormValues>;
}): JSX.Element {
  const term = props.watch('term');
  const presetDonationAmount = props.watch('presetDonationAmount');
  const customDonationAmount = props.watch('customDonationAmount');

  const title = props.product.is_donation
    ? 'Donation'
    : `${props.product.display_name} Subscription`;

  let termIndicator = '';

  if (props.product.is_donation) {
    termIndicator =
      term === 'one_time'
        ? 'one time'
        : term === 'month'
        ? 'recurring monthly'
        : 'recurring yearly';
  } else {
    termIndicator = term === 'one_time' ? '/ year (one time)' : '/ year (auto-renew)';
  }

  const productPriceCents = props.product.price_cents;
  const donationAmountCents = useMemo(
    () =>
      getDonation(presetDonationAmount, customDonationAmount, props.product, props.donationPrices),
    [presetDonationAmount, customDonationAmount, props.product, props.donationPrices]
  );

  const totalPriceCents = productPriceCents + donationAmountCents;
  const priceText = props.product.is_donation
    ? termIndicator
    : totalPriceCents > 0
    ? `$${totalPriceCents / 100} ${termIndicator}`
    : ' ';

  return (
    <Box flexShrink={0} textAlign="center">
      <Heading as="h3" size="lg" color={frontendTheme.primaryButtonBackground}>
        {title}
      </Heading>
      {priceText}
    </Box>
  );
}

function DevToolsWrapper(props: { control: Control<SubscribeFormValues> }): JSX.Element {
  // This is just a trick to get rid of a React warning during SSR.
  const [showDevTools, setShowDevTools] = useState(false);
  useEffect(() => {
    setShowDevTools(true);
  }, []);

  if (process.env.NEXT_PUBLIC_SHOW_RHF_DEVTOOLS !== 'true') {
    return <></>;
  }
  if (!showDevTools) {
    return <></>;
  }
  return <DevTool control={props.control} placement="top-left" />;
}

function ProductNotFoundPage(): JSX.Element {
  return (
    <FrontendLayout>
      <Box bg="white" pb="4">
        <Container maxW="container.md" py="8">
          <Heading as="h1" mb="4" size="4xl" textAlign="center">
            Sorry :(
          </Heading>

          <Box py="6" fontSize="larger" lineHeight="tall" textAlign="center">
            <Text>We couldn&apos;t find the selected product</Text>
          </Box>
        </Container>
      </Box>
    </FrontendLayout>
  );
}

export type ProductSubscribePagePropsType = {
  status: 'ok';
  savedOrder?: RetrievedSavedOrder;
  contactForRenew?: RetrievedContact;
  originalProductId?: number;
  products: Products;
  donationPrices: DonationPrice[];
  shows: string[];
  radiothonVouchers: RadiothonVoucher[];
  stripePublishableKey: string;
  homeData: HomePageData;
};

function ProductSubscribePageImpl({
  savedOrder,
  contactForRenew,
  originalProductId,
  products,
  donationPrices,
  shows,
  radiothonVouchers,
  stripePublishableKey,
  homeData,
}: ProductSubscribePagePropsType): JSX.Element {
  const router = useRouter();
  const queryProductId = router.query.productId as string;
  const isContinueMode = queryProductId === 'continue';

  const stripePromise = getStripe(stripePublishableKey);

  const defaultValues = useMemo(
    () =>
      getSubscribeFormDefaultValues(
        savedOrder,
        contactForRenew,
        originalProductId,
        queryProductId,
        products,
        donationPrices,
        homeData.donation_drive
      ),
    [
      savedOrder,
      contactForRenew,
      originalProductId,
      queryProductId,
      products,
      donationPrices,
      homeData,
    ]
  );

  const useFormReturnResult = useForm<SubscribeFormValues>({
    defaultValues: defaultValues,
  });

  useFormPersist('customer-subscribe-form', {
    restore: isContinueMode,
    watch: useFormReturnResult.watch,
    setValue: useFormReturnResult.setValue,
    isDirty: useFormReturnResult.formState.isDirty,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { aboveStickyDivRef, aboveStickyInView } = useStickyMonitor();

  const orderStatus = useFormReturnResult.watch('orderStatus');
  const productId = useFormReturnResult.watch('productId');

  const isLoadingDataForContinue = router.query.productId === 'continue' && productId === -1;
  const product = products.subscription_products.find((product) => product.id === productId);

  if (isLoadingDataForContinue) {
    // Just display an empty page until the data has loaded from local storage.
    return <div></div>;
  }
  if (!product) {
    return <ProductNotFoundPage />;
  }

  const vouchers = useFormReturnResult.watch('vouchers');

  function setProduct(productId: number): void {
    const newProduct = products.subscription_products.find((product) => product.id === productId);

    if (newProduct?.is_donation !== product?.is_donation) {
      useFormReturnResult.setValue('term', 'one_time');
      useFormReturnResult.setValue('presetDonationAmount', '0');
      useFormReturnResult.setValue('customDonationAmount', '');
    }

    useFormReturnResult.setValue('productId', productId);

    if (orderStatus !== 'pledged') {
      // When paying with pledges we don't let people change
      // their choices for vouchers. Note that if they happen to
      // change their product type to one which alows less vouchers
      // then we'll fix this up during order finalisation.
      const newVouchers = ensureCorrectVoucherCount(
        vouchers,
        newProduct ? newProduct.voucher_count : 0
      );
      useFormReturnResult.setValue('vouchers', newVouchers);
    }
  }

  return (
    <Elements stripe={stripePromise}>
      <FrontendLayout>
        <SubscriptionChooser
          products={products.subscription_products}
          isOpen={isOpen}
          onClose={onClose}
          setProduct={setProduct}
        />

        <Box>
          <Box ref={aboveStickyDivRef} bg="white" pb="4">
            <Container maxW="container.sm" py="8">
              <Heading as="h1" mb="4" size="4xl" textAlign="center">
                {getHeadingText(orderStatus, product.is_donation)}
              </Heading>

              {product.is_donation && (
                <DonationDrive homeData={homeData} linkToDonatePage={false} />
              )}

              {orderStatus === 'pledged' && (
                <Box>
                  <Box textAlign="center">
                    <Text>Order S{savedOrder?.id}</Text>
                  </Box>
                  <Alert status="success" mt="4" mb="8" alignItems="flex-start">
                    <AlertIcon />
                    <Stack spacing="2">
                      <Text>
                        Thank you for pledging your support to RTRFM. Below you can confirm or
                        update your details and pay your pledge online using your credit card.
                      </Text>
                      <Text>
                        Details correct? Just click the <strong>Checkout</strong> button at the
                        bottom of this page to go to the payment page!
                      </Text>
                    </Stack>
                  </Alert>
                </Box>
              )}

              {!product.is_donation && (
                <>
                  <ProductImage imageUrl={product.image_url} />
                  <Box
                    mt="6"
                    dangerouslySetInnerHTML={{
                      __html: product.extended_description || product.description,
                    }}
                  />
                </>
              )}
              {product.is_donation && (
                <Box textAlign="center" mt="6">
                  All donations over $2 to RTRFM are tax deductible
                </Box>
              )}
            </Container>
          </Box>

          <BottomSection>
            <Sticky aboveStickyInView={aboveStickyInView}>
              <Container maxW="container.sm">
                <Flex alignItems="center">
                  <Box flexGrow={1}>
                    <TitleAndPriceIndicator
                      product={product}
                      donationPrices={donationPrices}
                      customDonationAmountStripeId={
                        products.custom_donation_amount_stripe_product_id
                      }
                      watch={useFormReturnResult.watch}
                    />
                  </Box>

                  <Button
                    variant="outline"
                    colorScheme="pink"
                    flexGrow={0}
                    flexShrink={0}
                    onClick={(e) => {
                      onOpen();
                      e.preventDefault();
                    }}
                  >
                    Change
                  </Button>
                </Flex>
              </Container>
            </Sticky>
            <Container maxW="container.sm" px={0}>
              <DevToolsWrapper control={useFormReturnResult.control} />

              {/* set up the dev tool */}
              <SubscribeForm
                product={product}
                products={products}
                donationPrices={donationPrices}
                shows={shows}
                radiothonVouchers={radiothonVouchers}
                useFormReturnResult={useFormReturnResult}
              />
            </Container>
          </BottomSection>
        </Box>
      </FrontendLayout>
    </Elements>
  );
}

export type ProductSubscribePageErrorPropsType = {
  status: 'error';
  errorType: 'retrieved_saved_order' | 'unknown';
  message?: string;
};

function ProductSubscribeErrorPage(props: ProductSubscribePageErrorPropsType): JSX.Element {
  return (
    <FrontendLayout>
      <Box bg="white" pb="4">
        <Container maxW="container.md" py="8">
          <Heading as="h1" mb="4" size="4xl" textAlign="center">
            Sorry :(
          </Heading>

          <Box py="6" fontSize="larger" lineHeight="tall" textAlign="center">
            {props.errorType === 'retrieved_saved_order' && (
              <Text>We couldn&apos;t locate your order</Text>
            )}
            {props.errorType !== 'retrieved_saved_order' && (
              <Box>
                <Text>An unexpected error occured</Text>
                <Text>The error returned was:</Text>
                <code>
                  {props.status} - {props.message}
                </code>
              </Box>
            )}
            <Text>
              Please feel free to contact us at{' '}
              <a href="mailto:admin@rtrfm.com.au">admin@rtrfm.com.au</a> for assistance
            </Text>
          </Box>
        </Container>
      </Box>
    </FrontendLayout>
  );
}

export function ProductSubscribePage(
  props: ProductSubscribePagePropsType | ProductSubscribePageErrorPropsType
): JSX.Element {
  if (props.status === 'error') {
    return <ProductSubscribeErrorPage status={props.status} errorType={props.errorType} />;
  } else {
    return <ProductSubscribePageImpl {...props} />;
  }
}

export const getServerSideProps = async (): Promise<{
  props: ProductSubscribePagePropsType | ProductSubscribePageErrorPropsType;
}> => {
  const stripeConfig = await getStripeConfig();
  const stripePublishableKey = stripeConfig.useStripeTestGateway
    ? stripeConfig.stripeTestPublishableKey
    : stripeConfig.stripePublishableKey;

  const stripe = await createStripeServerSide(stripeConfig);

  const products = await getProducts();
  const donationPrices = await getDonationPrices(stripe);

  const fetchShowsResponse = await fetch(`${process.env.RAILS_API_SERVER}/rails_api/shows`);
  const shows = (await fetchShowsResponse.json()) as string[];

  const fetchRadiothonVouchers = await fetch(
    `${process.env.RAILS_API_SERVER}/rails_api/radiothon_vouchers`
  );
  const radiothonVouchers: Array<RadiothonVoucher> = await fetchRadiothonVouchers.json();

  const homeData = await getHomeData(undefined);

  return {
    props: {
      status: 'ok',
      products,
      shows,
      radiothonVouchers,
      stripePublishableKey,
      donationPrices,
      homeData,
    },
  };
};

export default ProductSubscribePage;
