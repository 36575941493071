import {
  Flex,
  Box,
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
} from '@chakra-ui/react';
import React from 'react';

import { SubscriptionProduct } from '../../lib/rails_api/getProducts';

function SingleSubscriptionSelectionPanel(props: {
  product: SubscriptionProduct;
  onSelect: (id: number) => void;
}): JSX.Element {
  return (
    <Flex direction="row" mb="4" pb="2" borderBottomStyle="solid" borderBottomWidth="thin">
      <Box flexGrow={1} mr="4">
        <Heading as="h3" size="md">
          {props.product.display_name}
        </Heading>
        <Text>{props.product.description}</Text>
      </Box>
      <Box flexGrow={0}>
        <Button
          onClick={(e) => {
            props.onSelect(props.product.id);
            e.preventDefault();
          }}
          mt="2"
          variant="solid"
          size="sm"
        >
          Select
        </Button>
      </Box>
    </Flex>
  );
}

export default function SubscriptionChooser(props: {
  products: SubscriptionProduct[];
  isOpen: boolean;
  onClose: () => void;
  setProduct: (productId: number) => void;
}): JSX.Element {
  function onSelect(productId: number): void {
    props.setProduct(productId);
    props.onClose();
  }

  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose Subscription</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {props.products.map((product) => (
              <SingleSubscriptionSelectionPanel
                key={product.id}
                product={product}
                onSelect={onSelect}
              />
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
