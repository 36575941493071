import axios from 'axios';

import { SubscribeFormValues } from '../../types/FormValues';

export async function saveSubcriptionOrder(
  formValues: SubscribeFormValues
): Promise<{ sessionId: string }> {
  if (formValues.productId === null) {
    throw new Error('No product specified');
  }

  console.log(
    'Submitting checkout data from customer frontend to Next backend',
    JSON.stringify(formValues, null, 2)
  );

  const result = await axios.post<{ sessionId: string }>(
    `/api/frontend/save_subscription_for_stripe_checkout`,
    formValues
  );
  return result.data;
}
