import {
  Alert,
  AlertIcon,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  NumberInput,
  NumberInputField,
  Stack,
} from '@chakra-ui/react';
import React from 'react';
import { Control, Controller, FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form';

import { SubscriptionProduct } from '../../lib/rails_api/getProducts';
import { SubscribeFormValues } from '../../types/FormValues';
import { RadiothonVoucher } from '../../types/RadiothonVoucher';
import { RecurringPaymentLoginBoxSection } from '../donate/RecurringPaymentLoginBoxSection';
import { FormPaneWrapper } from '../form/FormPaneWrapper';
import { RadioButtonGroup } from '../form/RadioButtonGroup';

export const donationOptions = [
  { value: '0', label: 'none' },
  { value: '5', label: '$5' },
  { value: '10', label: '$10' },
  { value: '20', label: '$20' },
  { value: '50', label: '$50' },
  { value: 'other', label: 'other' },
];

type Props = {
  control: Control<SubscribeFormValues>;
  register: UseFormRegister<SubscribeFormValues>;
  watch: UseFormWatch<SubscribeFormValues>;
  errors: FieldErrors<SubscribeFormValues>;
  product: SubscriptionProduct;
  shows: string[];
  radiothonVouchers: RadiothonVoucher[];
};
export function SubscriptionDetailsFormPane(props: Props): JSX.Element {
  const presetDonationAmount = props.watch('presetDonationAmount');
  const term = props.watch('term');

  return (
    <FormPaneWrapper>
      <Stack direction="column" spacing="4">
        <FormControl>
          <FormLabel>
            Would you like to add a tax-deductible donation onto your subscription?
            <Controller
              name="presetDonationAmount"
              control={props.control}
              render={({ field }) => (
                <Box mt="2">
                  <RadioButtonGroup
                    options={donationOptions}
                    onChange={field.onChange}
                    value={field.value}
                    name={field.name}
                  />
                </Box>
              )}
            />
          </FormLabel>
        </FormControl>

        {presetDonationAmount === 'other' && (
          <FormControl isInvalid={props.errors.customDonationAmount !== undefined}>
            <FormLabel>
              Donation Amount
              <InputGroup variant="filled">
                <InputLeftAddon>$</InputLeftAddon>
                <NumberInput defaultValue={0} min={0} precision={0} variant="filled">
                  <NumberInputField
                    {...props.register('customDonationAmount', {
                      required: true,
                    })}
                  />
                </NumberInput>
                <InputRightAddon>.00</InputRightAddon>
              </InputGroup>
            </FormLabel>
            <FormErrorMessage>
              {props.errors.customDonationAmount && <span>This field is required</span>}
            </FormErrorMessage>
          </FormControl>
        )}
        <FormControl>
          <FormLabel>
            Would you like your subscription (and donation amount) to auto-renew each year?
            <Alert status="info" variant="outline">
              <AlertIcon />
              You can cancel the auto-renew online at any time
            </Alert>
            <Controller
              name="term"
              control={props.control}
              render={({ field }) => (
                <Box mt="2">
                  <RadioButtonGroup
                    options={[
                      { value: 'one_time', label: 'no' },
                      { value: 'year', label: 'yes' },
                    ]}
                    onChange={field.onChange}
                    value={field.value}
                    name={field.name}
                  />
                </Box>
              )}
            />
          </FormLabel>
        </FormControl>

        <RecurringPaymentLoginBoxSection
          autoRenew={term !== 'one_time'}
          thanksMessage="Thanks for choosing to auto-renew your subscription!"
          loginReturnTo={`/subscribe/continue`}
          logoutReturnTo={`/subscribe/continue`}
        />
      </Stack>
    </FormPaneWrapper>
  );
}
