import { FormControl, FormLabel, Box } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';

import { chakraFrontendThemeReactSelectStyles } from '../../styles/frontendTheme';
import { SubscribeFormValues } from '../../types/FormValues';
import { FormPaneWrapper } from '../form/FormPaneWrapper';

const FavouriteShowFormPane = (props: { shows: string[] }): JSX.Element => {
  const selectStyles = useMemo(() => chakraFrontendThemeReactSelectStyles(false), []);
  const { control } = useFormContext<SubscribeFormValues>();

  const optionsForFavouriteShowSelect = [
    { value: '', label: 'All' },
    ...props.shows.map((show) => ({ label: show, value: show })),
  ];

  return (
    <FormPaneWrapper>
      <FormControl>
        <FormLabel>
          Do you have a favourite RTRFM show?
          <Box mt="2">
            <Controller
              name="favouriteShow"
              control={control}
              render={({ field }) => (
                <ReactSelect
                  styles={selectStyles}
                  {...field}
                  options={optionsForFavouriteShowSelect}
                  instanceId="favouriteShow"
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                  name={field.name}
                />
              )}
            />
          </Box>
        </FormLabel>
      </FormControl>
    </FormPaneWrapper>
  );
};

export default FavouriteShowFormPane;
