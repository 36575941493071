import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import Image from 'next/image';
import React from 'react';
import {
  Control,
  FieldArrayWithId,
  useFieldArray,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { SubscriptionProduct } from '../../lib/rails_api/getProducts';
import { SubscribeFormValues } from '../../types/FormValues';
import { RadiothonVoucher } from '../../types/RadiothonVoucher';
import { FormPaneWrapper } from '../form/FormPaneWrapper';

function SingleVoucherSelectionPanel(props: {
  voucher: { id: number; description: string; display_name: string; image_url_if_present?: string };
  onSelect: (id: number) => void;
}): JSX.Element {
  return (
    <Flex direction="row" mb="4" pb="2" borderBottomStyle="solid" borderBottomWidth="thin">
      <Box flexGrow={0} minWidth="128px" mr={4}>
        {props.voucher.image_url_if_present && props.voucher.image_url_if_present !== '' && (
          <Image
            unoptimized={true}
            src={props.voucher.image_url_if_present}
            alt={props.voucher.display_name}
            width="128px"
            height="80px"
          />
        )}
      </Box>
      <Box flexGrow={1} mr="4">
        <Heading as="h3" size="md">
          {props.voucher.display_name}
        </Heading>
        <Box dangerouslySetInnerHTML={{ __html: props.voucher.description }} />
      </Box>
      <Box flexGrow={0}>
        <Button
          onClick={(e) => {
            props.onSelect(props.voucher.id);
            e.preventDefault();
          }}
          mt="2"
          variant="solid"
          size="sm"
        >
          Select
        </Button>
      </Box>
    </Flex>
  );
}

export function VoucherChooser(props: {
  allowedVouchers: RadiothonVoucher[];
  setSelectedVoucherId: (newValue: number) => void;
  selectedVoucherId: number;
  readOnly: boolean;
}): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const selectedVoucher = props.allowedVouchers.find(
    (voucher) => voucher.id === props.selectedVoucherId
  );

  function onSelect(id: number): void {
    props.setSelectedVoucherId(id);
    onClose();
  }

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose Voucher</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SingleVoucherSelectionPanel
              voucher={{ id: -1, display_name: 'None', description: 'No voucher required' }}
              onSelect={onSelect}
            />
            {props.allowedVouchers.map((voucher) => (
              <SingleVoucherSelectionPanel onSelect={onSelect} key={voucher.id} voucher={voucher} />
            ))}
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Box mt={2} display={{ md: 'flex' }}>
        <Input
          variant="filled"
          readOnly
          mr="2"
          type="text"
          flexGrow={1}
          value={selectedVoucher ? selectedVoucher.display_name : 'None'}
        />
        {!props.readOnly && (
          <Box flexGrow={0} mt={{ base: 4, md: 0 }}>
            <Button
              variant="outline"
              colorScheme="pink"
              onClick={(e) => {
                onOpen();
                e.preventDefault();
              }}
            >
              Choose Voucher
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export function SubscriptionVoucherChooser(props: {
  allowedVouchers: RadiothonVoucher[];
  field: FieldArrayWithId<SubscribeFormValues, 'vouchers', 'id'>;
  index: number;
  setValue: UseFormSetValue<SubscribeFormValues>;
  watch: UseFormWatch<SubscribeFormValues>;
  readOnly: boolean;
}): JSX.Element {
  const fieldName = `vouchers.${props.index}.id` as const;
  const selectedVoucherId = props.watch(fieldName);

  function setSelectedVoucherId(id: number): void {
    props.setValue(fieldName, id.toString());
  }
  return (
    <VoucherChooser
      allowedVouchers={props.allowedVouchers}
      setSelectedVoucherId={setSelectedVoucherId}
      selectedVoucherId={parseInt(selectedVoucherId)}
      readOnly={props.readOnly}
    />
  );
}

type Props = {
  control: Control<SubscribeFormValues>;
  product: SubscriptionProduct;
  radiothonVouchers: RadiothonVoucher[];
  setValue: UseFormSetValue<SubscribeFormValues>;
  watch: UseFormWatch<SubscribeFormValues>;
};

export function VoucherFormPane(props: Props): JSX.Element {
  const { fields } = useFieldArray({
    control: props.control,
    name: 'vouchers',
  });

  const orderStatus = props.watch('orderStatus');

  const allowedVouchers = props.radiothonVouchers.filter(
    (voucher) => !voucher.is_over_18 || props.product.is_over_18
  );

  if (fields.length === 0) {
    return <></>;
  }

  if (props.product.voucher_count === 0) {
    // This is for when paying a pledge a user may select
    // a product with no vouchers even though they selected
    // a product with vouchers at pledge time. We don't just nuke
    // the voucher selection so that the use can switch between
    // product types and keep their voucher count.
    return <></>;
  }

  return (
    <FormPaneWrapper>
      <Stack direction="column" spacing="4">
        <Heading as="h4" size="xl">
          Good News!
        </Heading>
        <Text mt="2">
          As a thank you, your subscription entitles you to{' '}
          {fields.length === 1 ? 'a voucher' : `${fields.length} vouchers`} from our awesome
          business supporters.
        </Text>

        {fields.map((field, index) => (
          <FormControl key={index}>
            <FormLabel>
              {orderStatus === 'pledged' && (
                <Text mt="2">
                  When making your pledge, you selected the following
                  <>{props.product.voucher_count > 1 ? ` for voucher ${index + 1}?` : ' voucher'}</>
                  :
                </Text>
              )}
              {orderStatus !== 'pledged' && (
                <>
                  {props.product.voucher_count > 1
                    ? `What voucher would you like for voucher ${index + 1}?`
                    : 'What voucher would you like?'}
                </>
              )}
              <SubscriptionVoucherChooser
                allowedVouchers={allowedVouchers}
                field={field}
                index={index}
                setValue={props.setValue}
                watch={props.watch}
                readOnly={orderStatus === 'pledged'}
              />
            </FormLabel>
          </FormControl>
        ))}
      </Stack>
    </FormPaneWrapper>
  );
}
