import styled from '@emotion/styled';
import React from 'react';

const ImageContainer = styled.div`
  width: 300px;
  max-width: 100%;
  margin: 0 auto;

  .image-wrapper {
    width: 100%;
    height: 0;
    padding-bottom: calc(330 / 300 * 100%);
    position: relative;
  }

  .image {
    display: block;
    background-size: cover;
    border-style: none;
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;

    &:hover {
      background-position: right top;
    }
  }
`;

const ProductImage = (props: { imageUrl: string }): JSX.Element => {
  return (
    <ImageContainer>
      <div className="image-wrapper">
        <div className="image" style={{ backgroundImage: `url(${props.imageUrl})` }} />
      </div>
    </ImageContainer>
  );
};

export default ProductImage;
