import { FormControl, FormLabel, Input, FormErrorMessage, Box, Stack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { SubscribeFormValues } from '../../types/FormValues';
import { FormPaneWrapper } from './FormPaneWrapper';
import { RadioButtonGroup } from './RadioButtonGroup';

const GiftDonorFormPane = (): JSX.Element => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext<SubscribeFormValues>();
  const giftPurchaserName = watch('giftPurchaserName');
  const giftPurchaserEmail = watch('giftPurchaserEmail');
  const [isGift, setIsGift] = useState(giftPurchaserName && giftPurchaserEmail ? 'yes' : 'no');
  return (
    <FormPaneWrapper>
      <Stack direction="column" spacing="4">
        <FormControl>
          <FormLabel>
            Are you subscribing/donating as a gift for another person?
            <Box mt="2">
              <RadioButtonGroup
                options={[
                  { value: 'no', label: 'no' },
                  { value: 'yes', label: 'yes' },
                ]}
                onChange={setIsGift}
                value={isGift}
                name="Gift"
              />
            </Box>
          </FormLabel>
        </FormControl>

        {isGift === 'yes' && (
          <>
            <FormControl isInvalid={errors.giftPurchaserName !== undefined}>
              <FormLabel>
                Your name
                <Input
                  variant="filled"
                  mt="2"
                  {...register('giftPurchaserName', { required: isGift === 'yes' })}
                  type="text"
                />
              </FormLabel>
              <FormErrorMessage>
                {errors.giftPurchaserName && <span>This field is required</span>}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.giftPurchaserEmail !== undefined}>
              <FormLabel>
                Your email
                <Input
                  variant="filled"
                  mt="2"
                  {...register('giftPurchaserEmail', {
                    required: isGift === 'yes',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Entered value does not match email format',
                    },
                  })}
                  type="email"
                />
              </FormLabel>
              <FormErrorMessage>
                {errors.giftPurchaserEmail && (
                  <span>{errors.giftPurchaserEmail.message || 'This field is required'}</span>
                )}
              </FormErrorMessage>
            </FormControl>
          </>
        )}
      </Stack>
    </FormPaneWrapper>
  );
};

export default GiftDonorFormPane;
